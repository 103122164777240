import { QuestionUserBehaviour } from '../interfaces/question';
import { gEnvironment } from '../../../environments/global_environment';
import { Observable, ReplaySubject, switchMap } from 'rxjs';
import { AliceInfoDialogComponent } from '../../shared/dialogs/info-dialogs/alice-info-dialog/alice-info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/core/user-service/user.service';
import { Injectable } from '@angular/core';
import { Chat, Message, MessageAssistant } from '../interfaces/chat';

@Injectable({
  providedIn: 'root',
})
export class AliceService {
  onChatStart = new ReplaySubject<Chat>(1);
  onChatClose = new ReplaySubject<Chat>(1);

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private dialog: MatDialog
  ) {}

  startQuestionChat(question_id: string, question: QuestionUserBehaviour, preprationLevel?: number): Chat {
    const chat: Chat = {
      type: 'question',
      question_id,
      question,
      messages: new Array<Message>(),
      generating_answer: true,
      preparation_level: preprationLevel,
    };

    this.onChatStart.next(chat);
    return chat;
  }

  startSimulationTip(question_id: string, question: QuestionUserBehaviour): Chat {
    const chat: Chat = {
      type: 'simulation_tip',
      question_id,
      question,
      messages: new Array<Message>(),
      generating_answer: true,
      lock_response: true,
    };

    this.onChatStart.next(chat);
    return chat;
  }

  generateMessageChat(
    question: QuestionUserBehaviour,
    type: 'start' | 'response',
    chat_type: string,
    message?: string,
    previous_messages?: Array<Message>,
    preparationLevel?: number
  ): Observable<MessageAssistant> {
    return this.userService.getFuturaUser().pipe(
      switchMap(user =>
        this.http.post<MessageAssistant>(gEnvironment.lambdas_url + '/alice/question-answer', {
          message_type: type,
          message,
          previous_messages,
          chat_type,
          user_uuid: user.instance_id,
          language: user.content.language,
          preparationLevel: preparationLevel,
          // preparationLevel: 90,
          question: {
            text: question.text,
            answers_all: question.answer_all,
            answers_correct: question.answer_correct,
          },
        })
      )
    );
  }

  save(chat: Chat) {
    return this.http.post<void>(environment.be_url + '/questions/ai/save', { chat });
  }

  infoDialog(type: 'question' | 'simulation_tip' = 'question') {
    return this.dialog.open(AliceInfoDialogComponent, { data: { type }, maxWidth: '500px' });
  }

  notRunnableDialog() {
    return this.dialog.open(AliceInfoDialogComponent, { data: { type: 'not_runnable' }, maxWidth: '500px' });
  }

  generateTestFeedback(testResult: { [category: string]: number }): Observable<string> {
    return this.http.post<string>(environment.alice_api + '/feedback', { test_result: testResult });
  }
}
